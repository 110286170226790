import React from 'react';
import { navigate } from 'gatsby';
import { useSelector, useDispatch } from 'react-redux';
import { getPatientRecord, postPatientRecord } from '../../../actions/assessmentInfo';
import { createLoadingSelector } from '../../../reducers/api';
import useActiveSession from '../../../hooks/useActiveSession';
import PersonalInfo from '../../../components/Assessment/PersonalInfo';
import Layout from '../../../components/v2/Layout';

export default () => {
  const dispatch = useDispatch();
  const loading = useSelector(createLoadingSelector(['assessment/patient/get', 'assessment/patient/post']));

  useActiveSession(() => {
    dispatch(getPatientRecord());
  }, []);

  const nextStep = async (value) => {
    try {
      await dispatch(postPatientRecord(value));
      navigate(`/partners/consult/vitalSignInfo`);
    } catch (e) {
    }
  }

  return (
    <Layout bg="bg2" title="ข้อมูลสุขภาพเบื้องต้น">
      <PersonalInfo title={false} loading={loading} onSubmit={nextStep} ignoreFields={{ phone: 1 }}/>
    </Layout>
  );
}